"use client";

import { useState } from "react";
import { ReactionPicker } from "../reaction/reaction-picker";
import { CommentList } from "./comment-list";
import { Button } from "~/components/ui/button";
import { Loader2, MessageSquare } from "lucide-react";
import { api } from "~/trpc/react";
interface CommentsReactionsProps {
  ownerId: string;
}
export function CommentsReactions({
  ownerId
}: CommentsReactionsProps) {
  const [isCommentListExpanded, setIsCommentListExpanded] = useState(false);
  const {
    data: comments,
    isLoading
  } = api.comment.getByOwnerId.useQuery({
    ownerId
  });
  if (isLoading) return <div className="flex justify-center p-4">
        <Loader2 className="h-4 w-4 animate-spin" />
      </div>;
  const totalComments = comments?.reduce((acc, comment) => acc + 1 + (comment.replies?.length ?? 0), 0) ?? 0;
  return <div className="flex flex-col gap-2 border-t-[0.5px] p-4" data-sentry-component="CommentsReactions" data-sentry-source-file="comments-reactions.tsx">
      <div className="flex flex-row justify-between gap-2">
        <ReactionPicker ownerId={ownerId} ownerType="post" data-sentry-element="ReactionPicker" data-sentry-source-file="comments-reactions.tsx" />
        <Button variant="ghost" className="justify-start gap-2 rounded-full" onClick={() => setIsCommentListExpanded(!isCommentListExpanded)} data-sentry-element="Button" data-sentry-source-file="comments-reactions.tsx">
          <MessageSquare className="h-4 w-4" data-sentry-element="MessageSquare" data-sentry-source-file="comments-reactions.tsx" />
          {isCommentListExpanded ? "Kommentare verbergen" : `Kommentare ${totalComments > 0 ? ` (${totalComments})` : ""}`}
        </Button>
      </div>
      <div className="space-y-4">
        {isCommentListExpanded && <div className="duration-200 animate-in slide-in-from-top-2">
            <CommentList ownerId={ownerId} comments={comments ?? []} />
          </div>}
      </div>
    </div>;
}