export const REACTION_EMOJIS = [
  "👍",
  "🤔",
  "😁",
  "❤️",
  "🎉",
  "🌱",
  "🚀",
  "👀",
] as const;

export const REACTION_OWNER_TYPES = [
  "post",
  "comment",
  "project",
  "event",
] as const;
