"use client";

import { type FC } from "react";
import { cn } from "~/lib/utils";
import { Card, CardContent, CardHeader } from "~/components/ui/card";
import { Label } from "~/components/ui/label";
import { Switch } from "~/components/ui/switch";
import { Input } from "~/components/ui/input";
import { Textarea } from "~/components/ui/textarea";
import { EditableProperty } from "~/components/ui/editable-property";
import { OrganizationLogo } from "~/components/domain/organization/organization-logo";
import { useOrganization } from "~/components/domain/organization/client";
import { type OrganizationHeroWidget } from "../../model/widget-model";
import { useWidget } from "../../client/hooks";
import { ImageUpload } from "~/components/ui/image-upload";
export interface OrganizatioHeroWidgetEditProps {
  widget: OrganizationHeroWidget;
}
const INTERNAL__OrganizatioHeroWidgetEdit: FC<OrganizatioHeroWidgetEditProps> = ({
  widget
}) => {
  const {
    organization
  } = useOrganization();
  const {
    editedWidget,
    setEditedWidget
  } = useWidget<OrganizationHeroWidget>();
  const handleBannerImageChange = async (imageUrl: string) => {
    await setEditedWidget({
      ...editedWidget,
      configuration: {
        ...editedWidget.configuration,
        imageUrl
      }
    });
  };
  return <div className="relative w-full" data-sentry-component="INTERNAL__OrganizatioHeroWidgetEdit" data-sentry-source-file="organization-hero-widget-edit.tsx">
      {!editedWidget.configuration.showBanner && <div className="h-[70px] w-full bg-transparent" />}
      <Card className="w-full p-0 transition-all" data-sentry-element="Card" data-sentry-source-file="organization-hero-widget-edit.tsx">
        {editedWidget.configuration.showBanner && <CardHeader className="relative flex h-44 flex-col items-start justify-start overflow-hidden p-0">
            <ImageUpload imageUrl={editedWidget.configuration.imageUrl} onImageChange={handleBannerImageChange} ownerId={widget.id} ownerType="widget" className="h-full w-full" />
          </CardHeader>}
        <CardHeader className="relative flex h-12 flex-col items-start justify-start p-0" data-sentry-element="CardHeader" data-sentry-source-file="organization-hero-widget-edit.tsx">
          <div className="absolute -top-[75px] left-0">
            <OrganizationLogo organization={organization} size="xl" className="ml-4 mt-4 aspect-square w-24 rounded-sm border-2 border-[white] shadow-sm" data-sentry-element="OrganizationLogo" data-sentry-source-file="organization-hero-widget-edit.tsx" />
          </div>
        </CardHeader>
        <CardContent className="py-4" data-sentry-element="CardContent" data-sentry-source-file="organization-hero-widget-edit.tsx">
          <div className="text-2xl font-bold">
            <EditableProperty data-sentry-element="EditableProperty" data-sentry-source-file="organization-hero-widget-edit.tsx">
              {isActive => {
              return <Input className={cn(["border-none text-2xl font-bold", isActive ? "hover:bg-muted" : "hover:bg-muted"])} value={editedWidget.configuration.title} onChange={async e => {
                await setEditedWidget({
                  ...editedWidget,
                  configuration: {
                    ...editedWidget.configuration,
                    title: e.currentTarget.value
                  }
                });
              }} />;
            }}
            </EditableProperty>
          </div>
          <div className="mt-1 text-sm">
            <EditableProperty data-sentry-element="EditableProperty" data-sentry-source-file="organization-hero-widget-edit.tsx">
              {isActive => {
              return <Textarea className={cn(["resize-none border-none text-sm", isActive ? "hover:bg-muted" : "hover:bg-muted"])} value={editedWidget.configuration.description} onChange={async e => {
                await setEditedWidget({
                  ...editedWidget,
                  configuration: {
                    ...editedWidget.configuration,
                    description: e.currentTarget.value
                  }
                });
              }} />;
            }}
            </EditableProperty>
          </div>
        </CardContent>
      </Card>

      <div className="absolute -top-[20px] right-0 z-10 flex w-full flex-row justify-end">
        <div className="flex items-center space-x-1 text-sm text-muted-foreground">
          <Switch id={`show-banner-${widget.id}`} defaultChecked={editedWidget.configuration.showBanner} onCheckedChange={async isChecked => {
          await setEditedWidget({
            ...editedWidget,
            configuration: {
              ...editedWidget.configuration,
              showBanner: isChecked
            }
          });
        }} data-sentry-element="Switch" data-sentry-source-file="organization-hero-widget-edit.tsx" />
          <Label htmlFor={`show-banner-${widget.id}`} data-sentry-element="Label" data-sentry-source-file="organization-hero-widget-edit.tsx">Banner anzeigen</Label>
        </div>
      </div>
    </div>;
};
export const OrganizatioHeroWidgetEdit: FC<OrganizatioHeroWidgetEditProps> = props => {
  return <INTERNAL__OrganizatioHeroWidgetEdit {...props} data-sentry-element="INTERNAL__OrganizatioHeroWidgetEdit" data-sentry-component="OrganizatioHeroWidgetEdit" data-sentry-source-file="organization-hero-widget-edit.tsx" />;
};