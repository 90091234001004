"use client";

import { Button } from "~/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "~/components/ui/popover";
import { api } from "~/trpc/react";
import { Smile } from "lucide-react";
import { useState } from "react";
import { useSession } from "~/lib/hooks/session-provider";
import { REACTION_EMOJIS, type REACTION_OWNER_TYPES } from "~/lib/utils/reactions";
import { useLoginModal } from "~/lib/hooks/useLoginModal";
import { cn } from "~/lib/utils/utils";
interface ReactionPickerProps {
  ownerId: string;
  ownerType: (typeof REACTION_OWNER_TYPES)[number];
  order?: "normal" | "reverse";
}
export function ReactionPicker({
  ownerId,
  ownerType,
  order
}: ReactionPickerProps) {
  const {
    user
  } = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const utils = api.useUtils();
  const {
    onOpen: openLoginModal
  } = useLoginModal();
  const {
    data: reactions = []
  } = api.reaction.getReactions.useQuery({
    ownerId,
    ownerType
  });
  const toggleReaction = api.reaction.toggle.useMutation({
    onMutate: async ({
      emoji
    }) => {
      if (!user) {
        throw new Error("User must be logged in to react");
      }
      await utils.reaction.getReactions.cancel({
        ownerId,
        ownerType
      });
      const prevReactions = utils.reaction.getReactions.getData({
        ownerId,
        ownerType
      }) ?? [];
      const existingReaction = prevReactions.find(r => r.userId === user.id && r.emoji === emoji);
      const optimisticReactions = existingReaction ? prevReactions.filter(r => !(r.userId === user.id && r.emoji === emoji)) : [...prevReactions, {
        id: `temp-${Date.now()}`,
        userId: user.id,
        emoji,
        ownerId,
        ownerType,
        createdAt: new Date(),
        user: {
          id: user.id,
          profilePicture: user?.profilePicture ?? null
          // We don't want to include sensitive data in the optimistic reaction
        } as (typeof prevReactions)[number]["user"]
      }];
      utils.reaction.getReactions.setData({
        ownerId,
        ownerType
      }, optimisticReactions);
      setIsOpen(false);
      return {
        prevReactions
      };
    },
    onError: (_, __, context) => {
      utils.reaction.getReactions.setData({
        ownerId,
        ownerType
      }, context?.prevReactions);
    }
  });
  const userReactions = reactions.filter(r => r.userId === user?.id);
  const handleReaction = (event: React.MouseEvent<HTMLButtonElement>, emoji: (typeof REACTION_EMOJIS)[number]) => {
    event.stopPropagation();
    event.preventDefault();
    if (!user) {
      openLoginModal();
      return;
    }
    toggleReaction.mutate({
      emoji,
      ownerId,
      ownerType
    });
  };
  const handleTriggerClick = (open: boolean) => {
    if (!user) {
      openLoginModal();
      return;
    }
    setIsOpen(open);
  };
  return <div className={cn("flex items-center gap-1", order === "reverse" && "flex-row-reverse")} data-sentry-component="ReactionPicker" data-sentry-source-file="reaction-picker.tsx">
      <Popover open={isOpen} onOpenChange={handleTriggerClick} data-sentry-element="Popover" data-sentry-source-file="reaction-picker.tsx">
        <PopoverTrigger asChild className="h-6 px-2 py-0" data-sentry-element="PopoverTrigger" data-sentry-source-file="reaction-picker.tsx">
          <div className={cn("flex h-6 cursor-pointer items-center justify-center rounded-md p-1 hover:bg-gray-100", isOpen && "bg-gray-100")}>
            <Smile className="h-4 w-4" data-sentry-element="Smile" data-sentry-source-file="reaction-picker.tsx" />
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-2 duration-200 animate-in zoom-in-75" data-sentry-element="PopoverContent" data-sentry-source-file="reaction-picker.tsx">
          <div className="flex gap-1">
            {REACTION_EMOJIS.map(emoji => {
            const isSelected = userReactions.some(r => r.emoji === emoji);
            return <Button key={emoji} variant={isSelected ? "secondary" : "ghost"} size="sm" onClickCapture={e => handleReaction(e, emoji)} className="h-8 px-2 py-0" aria-label={`Reagiere mit ${emoji}`} aria-pressed={isSelected}>
                  {emoji}
                </Button>;
          })}
          </div>
        </PopoverContent>
      </Popover>
      <div className="flex gap-1 text-sm">
        {REACTION_EMOJIS.map(emoji => {
        const count = reactions.filter(r => r.emoji === emoji).length;
        if (count === 0) return null;
        const isUserReaction = userReactions.some(r => r.emoji === emoji);
        return <Button key={emoji} variant={isUserReaction ? "secondary" : "ghost"} size="sm" className="h-6 px-2 py-0" onClickCapture={e => handleReaction(e, emoji)} aria-label={`Reagiere mit ${emoji}`} aria-pressed={isUserReaction}>
              {emoji} {count > 1 && count}
            </Button>;
      })}
      </div>
    </div>;
}