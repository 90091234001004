"use client";

import { useEffect, useState } from "react";
import Image from "next/image";
import { toast } from "sonner";
import { InputImage } from "./input-image";
import { LoadingDots } from "./loading-dots";
import { UploadImageProvider, useUploadImage } from "~/lib/hooks";
interface ImageUploadProps {
  imageUrl?: string;
  onImageChange: (url: string) => Promise<void>;
  ownerId: string;
  ownerType: "user" | "organization" | "post" | "project" | "event" | "text-editor" | "widget";
  aspectRatio?: number;
  maxWidth?: number;
  maxHeight?: number;
  className?: string;
}
function INTERNAL__ImageUpload({
  imageUrl,
  onImageChange,
  ownerId,
  ownerType,
  aspectRatio = 640 / 192,
  maxWidth = 1200,
  maxHeight = 600,
  className
}: ImageUploadProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    selectedFile,
    setSelectedFile,
    storeAndUploadFile
  } = useUploadImage();
  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    void handleUploadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);
  async function handleImageSelect(file: File) {
    const fileWithPreview = Object.assign(file, {
      preview: URL.createObjectURL(file),
      path: ""
    });
    setSelectedFile(fileWithPreview);
  }
  const handleUploadFile = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const storeAndUploadFileResult = await storeAndUploadFile({
      ownerId,
      ownerType,
      makePublic: true
    });
    if (!storeAndUploadFileResult) {
      toast.error("Etwas ist schief gelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
      setLoading(false);
      return;
    }
    if (storeAndUploadFileResult.error) {
      toast.error(storeAndUploadFileResult.error.message);
      setLoading(false);
      return;
    }
    await onImageChange(storeAndUploadFileResult.result.publicUrl);
    setLoading(false);
  };
  return <div className={className} data-sentry-component="INTERNAL__ImageUpload" data-sentry-source-file="image-upload.tsx">
      <InputImage onChange={handleImageSelect} compress={{
      maxWidth,
      maxHeight
    }} aspectRatio={aspectRatio} shape="rect" className="group relative h-full w-full border-none hover:cursor-pointer" data-sentry-element="InputImage" data-sentry-source-file="image-upload.tsx">
        <>
          {!selectedFile && <>
              {imageUrl && <Image src={imageUrl} alt="Uploaded image" fill={true} className="object-cover" sizes="640px" />}
              {!imageUrl && <div className="h-full w-full bg-muted"></div>}
            </>}
          {selectedFile && <Image src={selectedFile.preview} alt="Selected image" fill={true} className="object-cover" sizes="640px" />}
          {loading && <div className="absolute inset-0 flex flex-row items-center justify-center bg-black/20">
              <LoadingDots color="#fff" />
            </div>}
        </>
      </InputImage>
    </div>;
}
export function ImageUpload(props: ImageUploadProps) {
  return <UploadImageProvider data-sentry-element="UploadImageProvider" data-sentry-component="ImageUpload" data-sentry-source-file="image-upload.tsx">
      <INTERNAL__ImageUpload {...props} data-sentry-element="INTERNAL__ImageUpload" data-sentry-source-file="image-upload.tsx" />
    </UploadImageProvider>;
}